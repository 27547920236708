/**
 * @file
 */

(function($) {

  $('body').on('tap', '#header a', _closeMainNav);
  $('body').on('click', '#header a', _closeMainNav);

  $('body').on('tap', '.header__menu_list_parent__icon', menuItemAction);
  $('body').on('click', '.header__menu_list_parent__icon', menuItemAction);
  $('body').on('mouseleave', '.header__menu_list_parent', _closeCollapsedChildren);

  $('body').on('tap', '.header__search_cancel', _cancelSearch);
  $('body').on('click', '.header__search_cancel', _cancelSearch);

  $('body').on('tap', '.header__menu_mobile', mobileMenuControlAction);
  $('body').on('click', '.header__menu_mobile', mobileMenuControlAction);

  $('body').on('click', '.header__search_icon', searchFormAction);
  $('body').on('tap', '.header__search_icon', searchFormAction);
  $('body').on('blur', '.header__search_form__input', _cancelSearch);

  $(document).keyup(function(event) {
    /* escape key maps to keycode 27 */
     if (event.keyCode == 27) {
      _closeMainNav();
      _cancelSearch();
    }
  });

  function searchFormAction(event) {
    _closeMainNav();
    $('.header__search_icon').toggleClass('visible hidden');
    $('.header__search_form').toggleClass('visible hidden');
    $('.header__search_form__input').first().focus();
    $('.header__search').toggleClass('active');
    $('.header__menu').addClass('search');
  }

  function menuItemAction(event) {
    $(this).parent().toggleClass('collapsed');
    $(this).parent().find('ul').toggleClass('collapsed');
    $(this).parent().find('i').toggleClass('fa-caret-down fa-caret-up');
  }

  function mobileMenuControlAction(event) {
    $('.header__menu').toggleClass('collapsed');
    $('.header__menu_mobile').toggleClass('collapsed');
    $('.header__menu_mobile__control').toggleClass('fa-bars fa-remove');
  }

  function _closeCollapsedChildren() {
    $.each($('.header__menu_list_parent.collapsed'), function() {
      $(this).removeClass('collapsed');
      $(this).find('i').toggleClass('fa-caret-down fa-caret-up');
      $(this).find('ul.collapsed').removeClass('collapsed');
    });
  }

  function _cancelSearch() {
    if ($('.header__search_form').hasClass('visible')) {
      $('.header__search_icon').toggleClass('visible hidden');
      $('.header__search_form').toggleClass('visible hidden');
    }
    if ($('.header__search').hasClass('active')) {
      $('.header__menu').removeClass('search');
      $('.header__search').removeClass('active');
    }
  }

  function _closeMainNav() {
    _closeCollapsedChildren();
    if ($('.header__menu').hasClass('collapsed')) {
      $('.header__menu').toggleClass('collapsed');
      $('.header__menu_mobile').removeClass('collapsed');
      $('.header__menu_mobile__control').toggleClass('fa-remove fa-bars');
    }
  }

  $(document).ready(function(event) {
    const blogPaths = ['/blogs', '/blogs-news', '/news-blogs'];
    const documentationPaths = ['/documentation'];
    const currentPath = `/${window.location.pathname.split('/')[1]}`;
    
    if (blogPaths.includes(currentPath)) $("a[href='/about']").parent('li').addClass('active');
    if (documentationPaths.includes(currentPath)) $("a[href='/developer']").parent('li').addClass('active'); 
    $("a[href='"+ currentPath +"']").addClass('is-active').parent('li').addClass('active');
  });

})(jQuery);
